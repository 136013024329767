import bootstrap from 'bootstrap'
import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/index';
import 'vue-search-select/dist/VueSearchSelect.css'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";
moment.locale('es')
createApp(App)
.use(router)
.mount('#app')
